import moment from 'moment';
export function initRelativeDates(containerNode) {
    containerNode.querySelectorAll('.js-relative-date').forEach(node => {
        initRelativeDate(node);
    });
}
export function observeRelativeDateContainers() {
    const containers = document.querySelectorAll('.js-relative-date-container');
    containers.forEach(container => {
        observeRelativeDateContainer(container);
    });
}
function initRelativeDate(node) {
    moment.locale(getLocale());
    node.textContent = moment(node.textContent).fromNow();
    node.classList.remove('js-relative-date');
}
function observeRelativeDateContainer(container) {
    const observer = new MutationObserver((() => {
        initRelativeDates(container);
    }));
    observer.observe(container, {
        attributes: true,
        childList: true,
        subtree: true,
    });
}
function getLocale() {
    return document.querySelector('html').dataset.lang;
}
