/* eslint-disable no-cond-assign */
export const fadeOut = (el, time, timeout) => {
    el.style.opacity = '1';
    let last = +new Date();
    const tick = () => {
        // @ts-ignore
        el.style.opacity = +el.style.opacity - (new Date() - last) / time;
        last = +new Date();
        if (+el.style.opacity >= 0) {
            /* eslint-disable-next-line */
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        }
    };
    setTimeout(tick, timeout);
};
export const fadeIn = (el, time) => {
    el.style.opacity = '0';
    let last = +new Date();
    const tick = function () {
        // @ts-ignore
        el.style.opacity = +el.style.opacity + (new Date() - last) / time;
        last = +new Date();
        if (+el.style.opacity <= 1) {
            /* eslint-disable-next-line */
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        }
    };
    tick();
};
