/* eslint-disable */
/**
* Interlay
* This creates a Popup / Overlay like UI Element
*/
class Interlay {
    /**
     * setup all the options
     * @param content
     * @param options
     * @param customContentWrapperClass string | null
     */
    constructor(content, options = {}, customContentWrapperClass) {
        this.stringDivider = '-';
        this.naming = {
            brand: 'interlay',
            instance: 'item',
            content: 'content',
            wrap: 'wrapper',
            toprow: 'toprow',
            close: 'close',
            btn: 'button',
            overlay: 'overlay',
            type: 'type',
            controls: 'controls',
        };
        this.types = {
            basic: 'basic',
            overlay: 'overlay',
        };
        this.classes = {
            instance: this.naming.brand + this.stringDivider + this.naming.instance,
            contentWrap: this.naming.brand + this.stringDivider + this.naming.content
                + this.stringDivider + this.naming.wrap,
            content: this.naming.brand + this.stringDivider + this.naming.content,
            toprow: this.naming.brand + this.stringDivider + this.naming.toprow,
            closeBtn: this.naming.brand + this.stringDivider + this.naming.close + this.stringDivider + this.naming.btn,
            overlay: this.naming.brand + this.stringDivider + this.naming.overlay,
            type: this.naming.brand + this.stringDivider + this.naming.type,
            controls: this.naming.brand + this.stringDivider + this.naming.controls,
        };
        this.content = content;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'alias' does not exist on type '{}'.
        this.alias = options.alias || this.generateAlias();
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
        this.type = options.type || this.types.basic;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'btnYes' does not exist on type '{}'.
        this.btnYes = options.btnYes || false;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'btnNo' does not exist on type '{}'.
        this.btnNo = options.btnNo || false;
        this.instance = null;
        this.createInstance(customContentWrapperClass);
    }
    /**
     * adds a class to this instance
     * @param className
     */
    addClass(className) {
        this.instance.classList.add(className);
    }
    /**
     * removes a class from this instance
     * @param className
     */
    removeClass(className) {
        this.instance.classList.remove(className);
    }
    /**
     * generate an alias to use as unique identifier
     * @returns {string}
     */
    generateAlias() {
        return this.naming.brand + this.stringDivider + this.naming.instance + this.stringDivider
            + Math.random().toString(32).substr(2, 10);
    }
    /**
     * create the interlay instance
     */
    createInstance(customContentWrapperClass) {
        // main
        this.instance = document.createElement('div');
        this.instance.id = this.alias;
        this.instance.classList.add(this.classes.instance);
        const contentWrap = document.createElement('div');
        contentWrap.classList.add(this.classes.contentWrap);
        if (customContentWrapperClass) {
            contentWrap.classList.add(customContentWrapperClass);
        }
        // close toprow wrapper
        const toprow = document.createElement('div');
        toprow.classList.add(this.classes.toprow);
        // close button
        const closeBtn = document.createElement('button');
        closeBtn.type = 'button';
        closeBtn.classList.add(this.classes.closeBtn);
        closeBtn.innerHTML = '<i class="icon-040-schliessen-104" data-icon=""></i>';
        // content
        const content = document.createElement('div');
        content.classList.add(this.classes.content);
        // controls
        const controls = document.createElement('div');
        controls.classList.add(this.classes.controls);
        // btnYes
        if (this.btnYes) {
            controls.appendChild(this.createBtnYes());
        }
        // btnNo
        if (this.btnNo) {
            controls.appendChild(this.createBtnNo());
        }
        switch (this.type) {
            case this.types.overlay: {
                this.instance.classList.add(this.classes.type + this.stringDivider + this.types.overlay);
                content.innerHTML = this.content;
                // create extra overlay
                const overlay = document.createElement('div');
                overlay.classList.add(this.classes.overlay);
                this.instance.appendChild(overlay);
                this.createOverlayCloseListener(overlay);
                break;
            }
            default:
                this.instance.classList.add(this.classes.type + this.stringDivider + this.types.basic);
                content.innerHTML = this.content;
        }
        // append listeners
        this.createCloseListener(closeBtn);
        // append children
        toprow.appendChild(closeBtn);
        contentWrap.appendChild(toprow);
        contentWrap.appendChild(content);
        contentWrap.appendChild(controls);
        this.instance.appendChild(contentWrap);
    }
    createOverlayCloseListener(overlay) {
        const self = this;
        overlay.addEventListener('click', () => {
            self.close();
        });
    }
    /**
     * add a close event listener to the close button
     * @param closeBtn
     */
    createCloseListener(closeBtn) {
        const self = this;
        closeBtn.addEventListener('click', () => {
            self.close();
        });
    }
    /**
     * create a Yes-Button and add an eventlistener, also closes this instance
     */
    createBtnYes() {
        const self = this;
        const btnYes = document.createElement('button');
        btnYes.innerText = this.btnYes.label;
        btnYes.addEventListener('click', () => {
            self.close();
            if (typeof self.btnYes.callback === 'function')
                self.btnYes.callback();
        });
        return btnYes;
    }
    /**
     * create a No-Button and add an eventlistener, also closes this instance
     */
    createBtnNo() {
        const self = this;
        const btnNo = document.createElement('button');
        btnNo.innerText = this.btnNo.label;
        btnNo.addEventListener('click', () => {
            self.close();
            if (typeof self.btnNo.callback === 'function')
                self.btnNo.callback();
        });
        return btnNo;
    }
    /**
     * close the active instance
     */
    close() {
        const closeInterlayEvent = new CustomEvent('closeInterlay');
        document.dispatchEvent(closeInterlayEvent);
        document.body.removeChild(this.instance);
    }
    /**
     * open the active instance
     */
    open() {
        document.body.appendChild(this.instance);
    }
}
module.exports = Interlay;
