const TableSelectors = {
    TABLE: '.js-table',
    TABLE_WRAPPER: '.js-table-wrapper',
    TABLE_ROW_COLLAPSE_TRIGGER: '.js-table-row-collapse',
    ROW: '.js-table-row',
    CELL: '.js-table-cell',
    YEAR_TRIGGER: '.js-year-handler',
    FLUID_CONTAINER: '.js-fluid-container',
    ABSOLUTE_CELL: '.js-is-absolute',
    SCROLL_SHADOW: '.js-scroll-shadow',
    FUNCTION_TRIGGER: '.js-function-trigger',
    META_ROW: '.js-meta-row',
    META_TAB: '.js-meta-tab',
    META_FUNCTION_WRAPPER: '.js-meta-function-wrapper',
    META_TAB_TRIGGER: '.js-meta-tab-trigger',
    EDIT: '.js-edit-table',
    EDIT_WRAPPER: '.js-edit-wrapper',
    EDIT_CBX: '.js-edit-cbx',
    SAVE_TRIGGER: '.js-save-config',
    PRINT_TRIGGER: '.js-print-table',
    BAR_CHART: '.js-bar-chart',
    LINE_CHART: '.js-line-chart',
    ROW_DOWNLOAD_TRIGGER: '.js-row-download-trigger',
    EXPAND_TABLE: '.js-expand-all',
};
export default TableSelectors;
