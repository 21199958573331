import { DEFAULT_STATE, HIDE_TOOLTIP, SHOW_TOOLTIP, } from './models';
const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SHOW_TOOLTIP:
            return Object.assign(Object.assign({}, state), { visible: true });
        case HIDE_TOOLTIP:
            return Object.assign(Object.assign({}, state), { visible: false });
        default:
            console.error('Action type not found');
            return Object.assign({}, state);
    }
};
export default reducer;
