var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
const Input = (_a) => {
    var { classes = [], attrs } = _a, props = __rest(_a, ["classes", "attrs"]);
    const cls = ['a-text-input', ...classes];
    return (React.createElement("input", Object.assign({ name: props.name, id: props.id, required: props.required, className: cls.join(' '), type: attrs && attrs.type ? attrs.type : 'text' }, attrs)));
};
export default Input;
