// @ts-expect-error ts-migrate(2306) FIXME: Remove this comment to see the full error message
import Interlay from '../../../../../design/1/js/app/components/interlay';
import { initRelativeDates } from '../../../../../design/1/js/app/components/relativeDate';
export default function initSysteminfo() {
    const htmlElement = document.querySelector('html');
    const shouldDisplaySysteminfoPopup = htmlElement.dataset.shouldDisplaySysteminfoPopup === 'true';
    const systeminfoUrl = '/_common/systeminfo';
    const hideSysteminfoUrl = '/_common/systeminfo/hideSysteminfo';
    let interlay = null;
    function displaySysteminfoPopup() {
        $.get(systeminfoUrl).then(response => {
            interlay = new Interlay(response, { type: 'overlay' });
            interlay.open();
            initRelativeDates(document.querySelector('.systeminfo'));
            return interlay;
        });
        return null;
    }
    if (shouldDisplaySysteminfoPopup) {
        displaySysteminfoPopup();
    }
    $(document).on('click', '.systeminfo-ok, .systeminfo-show-later', e => {
        e.preventDefault();
        const clickedButton = e.currentTarget;
        if (interlay !== null) {
            interlay.close();
        }
        $.post(hideSysteminfoUrl, {
            showLater: clickedButton.classList.contains('systeminfo-show-later'),
        });
    });
}
