import React from 'react';
import Icon from '../icon';
const AccordionBtn = ({ id, active, children, classes = [], attrs = {}, handleClick, }) => {
    const cls = active ? 'a-accordion-btn a-accordion-btn--active' : 'a-accordion-btn';
    return (React.createElement("button", Object.assign({ className: `${cls} ${classes.join(' ')}`, type: "button", "aria-controls": `accordion-panel-${id}`, "aria-expanded": active, onClick: handleClick }, attrs),
        children,
        React.createElement("span", { className: "a-accordion-btn__icon-plus" },
            React.createElement(Icon, { name: "plus" })),
        React.createElement("span", { className: "a-accordion-btn__icon-minus" },
            React.createElement(Icon, { name: "minus" }))));
};
export default AccordionBtn;
