const SocialShare = () => {
    const socialShareIcons = document.querySelectorAll('.js-social-ctrl');
    const articleLead = document.querySelector('.js-article-lead');
    if (socialShareIcons.length === 0 || articleLead === null) {
        return;
    }
    const articleTitle = encodeURIComponent(document.title);
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent(articleLead.textContent);
    const shareHandler = event => {
        event.preventDefault();
        event.stopPropagation();
        switch (event.currentTarget.dataset.type) {
            case 'facebook':
                window.open(`http://www.facebook.com/sharer/sharer.php?u=${url}&title=${articleTitle}&description=${text}&source=${articleTitle}&image=${encodeURIComponent($('*[property="og:image"]').attr('content'))}`);
                break;
            case 'twitter':
                window.open(`https://twitter.com/share?url=${url}`);
                break;
            case 'linkedin':
                window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${articleTitle}&summary=${text}&source=${articleTitle}`);
                break;
            case 'xing':
                window.open(`https://www.xing.com/spi/shares/new?url=${url}&title=${articleTitle}&description=${text}`);
                break;
            case 'whatsapp':
                window.location.href = `whatsapp://send?text=${url}`;
                break;
            case 'mail':
                window.open(`mailto:?subject=${document.title}&body=${articleLead.textContent}${encodeURIComponent(`\r\n${window.location}`)}`);
                break;
            default:
        }
    };
    for (const shareTrigger of Array.from(socialShareIcons)) {
        shareTrigger.addEventListener('click', shareHandler);
    }
};
export default SocialShare;
