import React from 'react';
import { render } from 'react-dom';
import Button from '../../ui/src/01-atoms/button';
document.addEventListener('DOMContentLoaded', () => {
    var _a;
    const buttonWrappers = document.querySelectorAll('.js-button-wrapper');
    if (buttonWrappers.length === 0) {
        return;
    }
    for (const buttonWrapper of Array.from(buttonWrappers)) {
        const type = (_a = buttonWrapper.dataset.type) !== null && _a !== void 0 ? _a : 'button';
        const buttonType = buttonWrapper.dataset.buttonType;
        const buttonLabel = buttonWrapper.dataset.buttonLabel;
        const classes = [buttonWrapper.dataset.additionalClass];
        const hasArrows = buttonType === 'primary' || buttonType === 'secondary';
        const attrs = {};
        if (buttonType === 'primary') {
            classes.push('a-btn--primary');
        }
        if (buttonWrapper.dataset.visible !== null) {
            attrs['data-visible'] = buttonWrapper.dataset.visible ? 'true' : 'false';
        }
        if (buttonWrapper.dataset.name !== null) {
            attrs['name'] = buttonWrapper.dataset.name; // eslint-disable-line
        }
        const button = (
        // @ts-ignore
        React.createElement(Button, { classes: classes, arrows: hasArrows, attrs: attrs, type: type }, buttonLabel));
        render(React.createElement(React.StrictMode, null, button), buttonWrapper);
    }
});
