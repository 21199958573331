import React, { useState } from 'react';
export default function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
    function handleSizeChange() {
        return setIsMobile(window.innerWidth < 1025);
    }
    React.useEffect(() => {
        window.addEventListener('resize', handleSizeChange);
        return () => {
            window.removeEventListener('resize', handleSizeChange);
        };
    }, [isMobile]);
    return isMobile;
}
