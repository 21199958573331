import React, { useEffect, useReducer } from 'react';
import HeaderSidebar from '../../02-molecules/header-sidebar';
import useIsMobile from './useIsMobile';
import HeaderTop from '../header-top';
import HeaderBottom from '../header-bottom';
import reducer, { COLLAPSE_HEADER, INITIAL_STATE, TOGGLE_COLLAPSE_HEADER, TOGGLE_SIDEBAR, UNCOLLAPSE_HEADER, } from './reducer';
const Header = ({ username, headerTitle, headerTriggerLabel, isLoggedIn, mainNavTitle, mainNavLinks, metaNavLinks, activeLangLabel, languageConfig, loginUrl, loginLabel, logoutUrl, logoutLabel, usermenuActions, directSearchUrl, searchFormAction, searchLabel, searchPlaceholder, additionalActions, }) => {
    const isMobile = useIsMobile();
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const collapseOffset = 150;
    const uncollapseOffset = 50;
    useEffect(() => {
        if (!isMobile) {
            window.addEventListener('scroll', scrollHandler, false);
        }
        return () => {
            if (!isMobile) {
                window.removeEventListener('scroll', scrollHandler, false);
            }
        };
    }, [isMobile]);
    function sidebarHandlerReact() {
        dispatch({
            type: TOGGLE_SIDEBAR,
        });
    }
    function collapseHandlerReact() {
        dispatch({
            type: TOGGLE_COLLAPSE_HEADER,
        });
    }
    const scrollHandler = function () {
        const html = document.querySelector('html');
        if (html !== null) {
            const scroll = html.scrollTop;
            if (scroll >= collapseOffset) {
                dispatch({
                    type: COLLAPSE_HEADER,
                });
            }
            else if (scroll <= uncollapseOffset) {
                dispatch({
                    type: UNCOLLAPSE_HEADER,
                });
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: "o-header", "data-module": "header" },
            React.createElement("nav", { className: "o-header__wrapper", "data-collapsed": state.isCollapsed, "data-clicked": state.isClicked },
                React.createElement("h1", { className: "u-sr-only" }, headerTitle),
                React.createElement("div", { className: "o-header__skip-lines" },
                    React.createElement("ul", { className: "list-unstyled" })),
                React.createElement("div", { className: "container-fluid" },
                    React.createElement(HeaderTop, { headerTriggerLabel: headerTriggerLabel, username: username, usermenuActions: usermenuActions, isClicked: state.isClicked, activeLangLabel: activeLangLabel, languageConfig: languageConfig, isLoggedIn: isLoggedIn, loginUrl: loginUrl, loginLabel: loginLabel, logoutUrl: logoutUrl, logoutLabel: logoutLabel, collapseHandlerReact: collapseHandlerReact, sidebarHandlerReact: sidebarHandlerReact, isSidebarOpen: state.isSidebarOpen, metaNavLinks: metaNavLinks, directSearchUrl: directSearchUrl, searchFormAction: searchFormAction, searchlabel: searchLabel, searchPlaceholder: searchPlaceholder, additionalActions: additionalActions }),
                    React.createElement(HeaderBottom, { mainNavTitle: mainNavTitle, mainNavLinks: mainNavLinks })))),
        React.createElement(HeaderSidebar, { isOpen: state.isSidebarOpen, handler: sidebarHandlerReact, mainNavLinks: mainNavLinks, mainNavTitle: mainNavTitle, metaNavLinks: metaNavLinks, activeLangLabel: activeLangLabel, languageConfig: languageConfig })));
};
Header.defaultProps = {
    isLoggedIn: false,
    username: null,
    usermenuActions: [],
    directSearchUrl: null,
    searchFormAction: null,
    additionalActions: [],
};
export default Header;
