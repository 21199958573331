const Sidebar = () => {
    const $window = $(window);
    const $trigger = $('.js-sidebar-trigger');
    const $sidebar = $('.js-sidebar');
    const isTouch = () => (
    // @ts-ignore
    ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    const setActive = $e => $e.attr('data-open', 'true');
    const setInactive = $e => $e.attr('data-open', 'false');
    const sidebarHandler = () => {
        if ($sidebar.attr('data-open') === 'true') {
            setInactive($sidebar);
            setInactive($trigger);
        }
        else {
            setActive($sidebar);
            setActive($trigger);
        }
    };
    const swipeHandler = event => {
        switch (event.type) {
            case 'swipeleft':
                setInactive($sidebar);
                setInactive($trigger);
                break;
            case 'swiperight':
                setActive($sidebar);
                setActive($trigger);
                break;
            default:
        }
    };
    const initEvents = () => {
        $trigger.on('click', sidebarHandler);
        if (isTouch) {
            $window.on('swipeleft swiperight', swipeHandler);
        }
    };
    initEvents();
};
export default Sidebar;
