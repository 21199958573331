import React from 'react';
import Icon from '../../01-atoms/icon';
const FooterToggle = ({ controls, text, expanded, options, classes = [], }) => (React.createElement("button", { className: `a-footer-toggler ${classes.join(' ')}`, type: "button", "aria-controls": controls, "aria-expanded": expanded, "data-module": "collapsible", "data-collapsible-options": JSON.stringify(options) },
    React.createElement("span", { className: "u-sr-only" }, text),
    React.createElement(Icon, { name: "chevron-up" }),
    React.createElement("p", null, text)));
FooterToggle.defaultProps = {
    classes: [],
    expanded: false,
    options: {
        activeCls: 'a-footer-toggler--active',
    },
    text: '',
};
export default FooterToggle;
