import React, { useState } from 'react';
import Icon from '../../01-atoms/icon';
import CircleAvatar from '../../01-atoms/circle-avatar';
const UsermenuDropdownToggle = ({ open, username, onClick, maxNameLength, amountOfHeaderActionsElements, }) => {
    const getInitals = () => {
        if (username === null) {
            return '';
        }
        return username.split(' ').map(x => x.charAt(0)).join('');
    };
    const trimUsername = () => {
        if (username === null) {
            return '';
        }
        if (username.length <= maxNameLength) {
            return username;
        }
        return `${username.substring(0, (maxNameLength - 3))}...`;
    };
    const baseClassName = 'm-usermenuDropdownToggle';
    const mobileLeftPositionClass = `-leftPosition_${amountOfHeaderActionsElements}`;
    return (React.createElement("button", { type: "button", className: `${baseClassName} ${mobileLeftPositionClass}`, "aria-expanded": open, onClick: onClick },
        React.createElement(CircleAvatar, { initials: getInitals() }),
        React.createElement("div", { className: `${baseClassName}__username`, "aria-expanded": open },
            React.createElement("span", null, trimUsername())),
        React.createElement("div", { className: `${baseClassName}__toggleIndicator`, "aria-expanded": open },
            React.createElement(Icon, { name: "chevron-down" }))));
};
const UsermenuDropdownItem = ({ baseClass, hasSectionDivider, url, icon, label, }) => (React.createElement("li", { className: `${baseClass}__dropdownItem ${hasSectionDivider ? ' -sectionDivider' : ''}` },
    React.createElement("a", { href: url !== null && url !== void 0 ? url : '' },
        React.createElement("span", { className: `${baseClass}__dropdownItemIcon` }, icon ? React.createElement(Icon, { name: icon }) : ''),
        label)));
const UsermenuDropdown = ({ entries, dropdownOpen, amountOfHeaderActionsElements, }) => {
    let dropdownEntries;
    const baseClass = 'm-usermenuDropdown';
    if (entries.length > 0) {
        let previousSection = 0;
        dropdownEntries = entries.map(item => {
            let hasSectionDivider = false;
            if (previousSection === 0) {
                previousSection = item.section;
                hasSectionDivider = true;
            }
            else if (previousSection !== item.section) {
                previousSection = item.section;
                hasSectionDivider = true;
            }
            return (React.createElement(UsermenuDropdownItem, { baseClass: baseClass, hasSectionDivider: hasSectionDivider, url: item.url, icon: item.icon, label: item.label, key: item.keyIdentifier }));
        });
    }
    const mobileLeftPositionClass = `-leftPosition_${amountOfHeaderActionsElements}`;
    return (React.createElement("ul", { className: `${baseClass} ${dropdownOpen ? '' : '-closed'} ${mobileLeftPositionClass}` }, dropdownEntries));
};
const UsermenuAvatar = ({ isLoggedIn, loginUrl, loginLabel, username, usermenuActions, amountOfHeaderActionsElements, }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleChange = () => setDropdownOpen(!dropdownOpen);
    if (!isLoggedIn) {
        return (React.createElement("a", { className: "m-usermenuAvatar m-usermenuAvatar__anonymous", href: loginUrl },
            React.createElement("span", null,
                React.createElement(Icon, { name: "user" }),
                React.createElement("span", null, loginLabel))));
    }
    return (React.createElement("div", { className: `m-usermenuAvatar ${dropdownOpen ? '-isOpen' : ''}` },
        React.createElement(UsermenuDropdown, { entries: usermenuActions, dropdownOpen: dropdownOpen, amountOfHeaderActionsElements: amountOfHeaderActionsElements }),
        React.createElement(UsermenuDropdownToggle, { open: dropdownOpen, onClick: handleChange, username: username, maxNameLength: 30, amountOfHeaderActionsElements: amountOfHeaderActionsElements })));
};
UsermenuAvatar.defaultProps = {
    username: null,
    usermenuActions: [],
};
UsermenuDropdownItem.defaultProps = {
    icon: null,
    url: null,
};
export default UsermenuAvatar;
