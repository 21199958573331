import React from 'react';
import Link from '../../01-atoms/link';
const LinkList = ({ links, classes = [], linkClasses = [], }) => (React.createElement("ul", { className: `m-linkList ${classes.join(' ')}` }, links && links.map(link => {
    const cls = link.classes ? link.classes : [];
    return (React.createElement("li", { className: "m-linkList__item", key: encodeURIComponent(link.children.toString()), "data-active": link.isActiveLink },
        React.createElement(Link, { href: link.href, classes: [...linkClasses, ...cls], icon: link.icon, attrs: link.attrs, isActiveLink: link.isActiveLink }, link.children)));
})));
LinkList.defaultProps = {
    linkClasses: [],
    classes: [],
};
export default LinkList;
