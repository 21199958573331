import React from 'react';
import Icon from '../icon';
const SocialLink = ({ icon, label, classes = [], href = '#', }) => {
    const cls = ['a-social-link', ...classes];
    const newWindowText = 'Link öffnet in neuem Fenster.';
    return (React.createElement("a", { href: href, className: cls.join(' '), rel: "external noopener nofollow noreferrer", target: "_blank" },
        React.createElement(Icon, { name: icon }),
        React.createElement("span", { className: "u-sr-only" },
            label,
            ' ',
            newWindowText)));
};
export default SocialLink;
