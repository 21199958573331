import React from 'react';
import { render } from 'react-dom';
import Footer from '../../ui/src/03-organisms/footer/index';
document.addEventListener('DOMContentLoaded', () => {
    const footerWrapper = document.querySelector('.js-footer-wrapper');
    if (footerWrapper === null) {
        return;
    }
    const footerSections = JSON.parse(footerWrapper.dataset.footerSections);
    const footerBottomLinks = JSON.parse(footerWrapper.dataset.footerBottomLinks);
    function initCookieFooter() {
        const cookieLink = document.querySelector('.ts-cookie-link');
        if (!cookieLink) {
            return;
        }
        cookieLink.addEventListener('click', () => {
            window.Optanon.ToggleInfoDisplay();
        });
    }
    function initToTopButton() {
        const hideToTopButton = () => {
            const toTopButton = document.querySelector('.o-footer__toTopButton');
            if (!toTopButton) {
                return;
            }
            toTopButton.style.display = 'none';
        };
        const showToTopButton = () => {
            const toTopButton = document.querySelector('.o-footer__toTopButton');
            if (!toTopButton) {
                return;
            }
            if (toTopButton.style.display === 'none') {
                toTopButton.style.display = 'block';
            }
        };
        window.addEventListener('scroll', () => {
            if (window.scrollY <= 450) {
                hideToTopButton();
            }
            else {
                showToTopButton();
            }
        });
        hideToTopButton();
    }
    render(React.createElement(React.StrictMode, null,
        React.createElement(Footer, { fixed: false, title: footerWrapper.dataset.footerTitle, footerSections: footerSections, footerBottomLinks: footerBottomLinks, toTopButtonLabel: footerWrapper.dataset.toTopLabel })), footerWrapper);
    initCookieFooter();
    initToTopButton();
});
