/* eslint-disable react/no-danger */
import React from 'react';
import FooterToggler from '../../02-molecules/footer-toggler';
import FooterBottom from '../footer-bottom';
import FooterSections from '../footer-sections';
import ToTopLabel from '../../02-molecules/to-top-label';
const togglerOptions = {
    animate: true,
    activeCls: 'a-footer-toggler--active',
    targetActiveCls: 'o-footer__toggable--active',
};
const Footer = ({ title, fixed, footerSections, footerBottomLinks, toTopButtonLabel, }) => {
    const sections = React.createElement(FooterSections, { footerSections: footerSections });
    const bottom = React.createElement(FooterBottom, { footerBottomLinks: footerBottomLinks });
    const footerToTopButton = toTopButtonLabel !== null ? (React.createElement("div", { className: "o-footer__toTopButton", "data-fixed": fixed },
        React.createElement("div", { className: "container-fluid" },
            React.createElement(ToTopLabel, { label: toTopButtonLabel })))) : '';
    const footerContent = fixed ? (React.createElement(React.Fragment, null,
        React.createElement(FooterToggler, { controls: "footer-toggable", text: title, options: togglerOptions }),
        React.createElement("div", { className: "o-footer__toggable", id: "footer-toggable" },
            React.createElement("div", { className: "o-footer__sections-container" },
                React.createElement("div", { className: "l-constrainer container-fluid" }, sections)),
            bottom))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "o-footer__sections-container" },
            React.createElement("div", { className: "l-constrainer" }, sections)),
        bottom));
    return (React.createElement("footer", { className: fixed ? 'o-footer o-footer--fixed' : 'o-footer', "data-module": "footer" },
        React.createElement("h1", { className: "u-sr-only" }, title),
        footerToTopButton,
        footerContent));
};
Footer.defaultProps = {
    title: '',
    fixed: false,
    toTopButtonLabel: 'nach oben',
};
export default Footer;
