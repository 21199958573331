import React from 'react';
import Icon from '../../01-atoms/icon';
import Button from '../../01-atoms/button';
const ToTopLabel = ({ label, }) => {
    const baseClass = 'm-toTopLabel';
    return (React.createElement(Button, { classes: [baseClass, 'js-to-top'], attrs: { 'data-module': 'to-top' } },
        React.createElement("div", { className: `${baseClass}__content` },
            React.createElement("span", { className: "hidden-xs hidden-sm" }, label),
            React.createElement("span", { className: "u-sr-only" }, label),
            React.createElement(Icon, { name: "chevron-up" }))));
};
ToTopLabel.defaultProps = {
    label: null,
};
export default ToTopLabel;
