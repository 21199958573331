import React from 'react';
import HeaderMetaNav from '../header-meta-nav';
import HeaderMainNav from '../header-main-nav';
import LanguageSwitch from '../language-switch';
const HeaderSidebar = ({ isOpen, mainNavTitle, mainNavLinks, metaNavLinks, activeLangLabel, languageConfig, handler, }) => {
    const baseClass = 'm-headerSidebar';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: baseClass, "data-open": isOpen },
            React.createElement("div", { className: `${baseClass}__mainNavWrapper` },
                React.createElement(HeaderMainNav, { mainNavLinks: mainNavLinks, mainNavTitle: mainNavTitle })),
            React.createElement("div", { className: `${baseClass}__bottomWrapper` },
                React.createElement("div", { className: `${baseClass}__metaNavWrapper` },
                    React.createElement(HeaderMetaNav, { metaNavLinks: metaNavLinks })),
                React.createElement("div", { className: `${baseClass}__languageSwitchWrapper` },
                    React.createElement(LanguageSwitch, { activeLangLabel: activeLangLabel, languageConfig: languageConfig, dropdownKeyAddition: "headerSidebar" })))),
        React.createElement("div", { className: `${baseClass}__overlay`, "data-open": isOpen, onClick: () => handler(), "aria-hidden": "true" })));
};
HeaderSidebar.defaultProps = {
    mainNavTitle: undefined,
};
export default HeaderSidebar;
