$(() => {
    const $container = $('.js-search');
    if ($container.length === 0) {
        return;
    }
    const $searchResults = $container.find('.js-search-results');
    const loadMoreUrl = $container.data('load-more-url');
    let locked = false;
    const loadMore = function () {
        if (locked) {
            return;
        }
        locked = true;
        $.ajax({
            url: loadMoreUrl,
            method: 'get',
            data: {
                'search-term': $container.attr('data-search-searchstring'),
                skip: $searchResults.children().length,
            },
            success(rows) {
                rows.forEach(row => {
                    const $row = $(row);
                    $searchResults.append($row);
                });
                if (rows.length) {
                    locked = false;
                }
            },
            error(xhr, status, err) {
                /* eslint no-console: 0 */
                console.error('load more failed in search:', '(', xhr.status, err, ')');
            },
        });
    };
    $(window).on('scroll', function () {
        const window = $(this);
        const searchResultTop = $searchResults.offset().top;
        const searchResultBottom = searchResultTop + $searchResults.innerHeight();
        const scrollHeight = window.scrollTop();
        const scrollBottom = scrollHeight + window.height();
        if (scrollBottom >= searchResultBottom) {
            loadMore();
        }
    });
});
