import React from 'react';
import AccordionBtn from '../../01-atoms/accordion-btn';
import AccordionPanel from '../../01-atoms/accordion-panel';
import Link from '../../01-atoms/link';
import LinkList from '../link-list';
import SocialLinks from '../social-links';
const FooterSection = ({ id, link, linkList, socialLinks = [], text, title, }) => (React.createElement("aside", { className: "m-footer-section" },
    React.createElement("h3", { className: "m-footer-section__title" }, title),
    React.createElement(AccordionBtn, { classes: ['a-accordion-btn--footer'], id: id }, title),
    React.createElement(AccordionPanel, { classes: ['a-accordion-panel--footer'], active: true, id: id },
        React.createElement("div", { className: "m-footer-section__body" },
            text && React.createElement("p", null, text),
            link && (React.createElement("p", null,
                React.createElement(Link, { href: link.href, classes: link.classes, iconsCTA: link.iconsCTA, attrs: link.attrs }, link.children))),
            socialLinks.length > 0 && React.createElement(SocialLinks, { socialLinks: socialLinks }),
            linkList && (React.createElement(LinkList, { links: linkList, linkClasses: ['a-link--footer'] }))))));
FooterSection.defaultProps = {
    link: null,
    socialLinks: [],
    text: null,
};
export default FooterSection;
