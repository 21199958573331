import '../scss/app.scss';
import 'jquery-migrate';
import 'jquery';
import 'picturefill';
import 'jquery-touch-events';
import 'chart.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/carousel';
// view
import PdfPrinting from './app/helpers/pdfPrinting';
import Storage from './app/helpers/localStorage';
import Comments from './app/components/comments';
import Sidebar from './app/components/sidebar';
import LangSelect from './app/components/langSelect';
import SocialShare from './app/components/socialShare';
import Slider from './app/components/slider';
import UserSurvey from './app/components/userSurvey';
import Initialize from './app/components/initializer';
import ZaFaTable from './app/components/zaFaTable';
import Highlight from './app/components/highlight';
import DeepLink, { copyToClipboard } from './app/components/deepLink';
// widgets
import '../../../platform/widgets/view/accordion/js/accordion';
import '../../../platform/widgets/view/search/js/loadMore';
// view modules
import initSysteminfo from '../../../platform/modules/common/systeminfo/ts/systeminfo';
// storybook
import '../templates/components/header/header';
import '../templates/components/footer/footer';
import '../templates/components/button/button';
import '../templates/ui/src/js/index';
import initLinkListWidget from '../../../platform/widgets/view/linklist/ts/_linkslist';
$.migrateMute = true;
document.addEventListener('DOMContentLoaded', () => {
    const PdfPrinter = new PdfPrinting();
    const Initializer = new Initialize(PdfPrinter);
    const TableService = new ZaFaTable(Storage, PdfPrinter);
    TableService.initialize();
    Sidebar();
    LangSelect();
    Comments();
    SocialShare();
    // has been deactivated with T-9428
    // import HelpTables from './app/components/helpTables';
    // HelpTables();
    Slider();
    UserSurvey();
    Initializer.initEvents();
    initSysteminfo();
    // widgets
    initLinkListWidget();
    document.querySelectorAll('.js-deeplink-trigger').forEach(el => {
        DeepLink(el);
    });
    document.querySelectorAll('.js-deeplink-copy-no-overlay').forEach(el => {
        el.addEventListener('click', copyToClipboard);
    });
});
window.addEventListener('load', () => {
    Highlight();
});
