import { fadeOut } from '../utilities/motion';
const DEEPLINK_OVERLAY = '.js-deeplink-overlay';
const DEEPLINK_REFERENCE = '.js-deeplink-reference';
const DEEPLINK_INPUT = '.js-deeplink-input';
const DEEPLINK_CLOSE = '.js-deeplink-close';
const DEEPLINK_COPY = '.js-deeplink-copy';
const DEEPLINK_COPY_MSG = '.js-deeplink-msg';
const SELECTOR_ROW_TITLE_CELL = 'td.js-table-cell.js-is-absolute[data-sort-order="0"]';
const DeepLink = el => {
    const deeplinkOverlay = document.querySelector(DEEPLINK_OVERLAY);
    const deeplinkReference = deeplinkOverlay.querySelector(DEEPLINK_REFERENCE);
    const deeplinkInput = deeplinkOverlay.querySelector(DEEPLINK_INPUT);
    const deeplinkClose = deeplinkOverlay.querySelector(DEEPLINK_CLOSE);
    const deeplinkCopy = deeplinkOverlay.querySelector(DEEPLINK_COPY);
    const generateCroppedUrl = (url, identifier) => {
        const base = url.split('?')[0];
        return `${base}?highlighted=${identifier}`;
    };
    const onDeepLinkOpen = event => {
        event.stopPropagation();
        const highlightedId = el.dataset.highlight;
        let url;
        if (highlightedId) {
            url = generateCroppedUrl(window.location.href, highlightedId);
        }
        else if (el.dataset.url.startsWith('/')) {
            url = `${window.location.origin}${el.dataset.url}`;
        }
        else {
            url = el.dataset.url;
        }
        const deeplinkOrigin = el.closest('.sv-widget');
        switch (deeplinkOrigin.classList[1]) {
            default:
                break;
            case 'sv-widget-zafa': {
                const title = $(el).closest('.plf-zafa-row').find(SELECTOR_ROW_TITLE_CELL).attr('data-cell-value');
                if (title)
                    deeplinkReference.textContent = title;
                break;
            }
        }
        deeplinkInput.setAttribute('value', url);
        deeplinkCopy.addEventListener('click', copyToClipboard);
        deeplinkClose.addEventListener('click', onDeeplinkClose);
        document.addEventListener('keydown', handleKeydown);
        document.querySelector(DEEPLINK_OVERLAY).setAttribute('data-visible', 'true');
        deeplinkInput.focus();
    };
    const handleKeydown = (e) => {
        if (e.key === 'Escape') {
            onDeeplinkClose();
        }
    };
    const onDeeplinkClose = () => {
        deeplinkOverlay.setAttribute('data-visible', 'false');
        document.removeEventListener('keydown', handleKeydown);
        deeplinkCopy.removeEventListener('click', copyToClipboard);
    };
    const bindEvents = () => {
        el.addEventListener('click', onDeepLinkOpen);
    };
    const init = () => {
        bindEvents();
    };
    init();
};
export const copyToClipboard = deeplinkCopyClickEvent => {
    const deeplinkCopyEl = deeplinkCopyClickEvent.currentTarget;
    const deepLinkCtrls = deeplinkCopyEl.closest('.plf-zafa-deeplink-ctrls');
    if (deepLinkCtrls === null) {
        return;
    }
    const currentDeepLink = deepLinkCtrls.querySelector('.plf-zafa-deeplink-input');
    if (currentDeepLink === null) {
        return;
    }
    navigator.clipboard.writeText(currentDeepLink.value);
    const deepLinkCopyMessage = deepLinkCtrls.querySelector(DEEPLINK_COPY_MSG);
    if (deepLinkCopyMessage !== null) {
        deepLinkCopyMessage.style.opacity = '1';
        fadeOut(deepLinkCopyMessage, 3000, 1500);
    }
    $(currentDeepLink).trigger('blur');
};
export default DeepLink;
