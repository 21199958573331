import React from 'react';
import Icon from '../../01-atoms/icon';
const HeaderActionsDropdownEntry = ({ icon, label, onClick, url, }) => {
    const baseClass = 'm-headerActionsDropdownEntry';
    const buttonInner = (React.createElement(React.Fragment, null,
        icon
            ? (React.createElement("span", { className: `${baseClass}__icon` },
                React.createElement(Icon, { name: icon })))
            : '',
        React.createElement("span", { className: `${baseClass}__dropdownlabel` }, label)));
    return (React.createElement("li", { className: baseClass }, onClick !== undefined
        ? (React.createElement("button", { type: "button", onClick: onClick }, buttonInner))
        : (React.createElement("a", { href: url }, buttonInner))));
};
const HeaderActionsDropdown = ({ entries = [], dropdownKeyAddition, dropdownOpen, }) => {
    let dropdownEntries;
    const baseClass = 'm-headerActionsDropdown';
    if (entries.length > 0) {
        dropdownEntries = entries.map(item => (React.createElement(HeaderActionsDropdownEntry, { section: item.section, keyIdentifier: item.keyIdentifier, label: item.label, classes: item.classes, url: item.url, onClick: item.onClick, icon: item.icon, key: `${item.keyIdentifier}-${dropdownKeyAddition}` })));
    }
    return (React.createElement("ul", { className: `${baseClass} ${dropdownOpen ? '' : '-closed'}` }, dropdownEntries));
};
HeaderActionsDropdownEntry.defaultProps = {
    children: [],
    icon: null,
    keyIdentifier: null,
    url: null,
};
export default HeaderActionsDropdown;
