import React from 'react';
import Icon from '../icon';
const MetaLink = ({ children, classes = [], href, icon, iconafter, label, onClick, type, }) => {
    const CustomTag = href ? 'a' : 'button';
    return (React.createElement(CustomTag, { href: href, className: ['a-meta-link', ...classes].join(' '), onClick: onClick, type: type },
        icon
            ? React.createElement(Icon, { name: icon }) : '',
        React.createElement("span", { className: "a-meta-link__label" }, label),
        iconafter
            ? React.createElement(Icon, { name: iconafter }) : '',
        children && children));
};
MetaLink.defaultProps = {
    children: null,
    classes: [],
    href: null,
    icon: null,
    iconafter: null,
    label: null,
    onClick: null,
    type: undefined,
};
export default MetaLink;
