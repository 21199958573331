import React, { useState } from 'react';
import HeaderActionsDropdownToggle from '../header-actions-dropdown-toggle';
import HeaderActionsDropdown from '../header-actions-dropdown';
import Icon from '../../01-atoms/icon';
const LanguageSwitch = ({ activeLangLabel, languageConfig, isLanguageSwitch, dropdownKeyAddition, }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleChange = () => setDropdownOpen(!dropdownOpen);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "m-languageSwitch", "data-lang-switch": isLanguageSwitch },
            React.createElement(HeaderActionsDropdownToggle, { open: dropdownOpen, icon: "globe", label: activeLangLabel, onClick: handleChange }),
            React.createElement(HeaderActionsDropdown, { dropdownOpen: dropdownOpen, entries: languageConfig, dropdownKeyAddition: dropdownKeyAddition }),
            React.createElement("div", { className: "m-languageSwitch-sidebar" },
                React.createElement(Icon, { name: "globe" }),
                React.createElement("select", { onChange: e => { window.location.assign(e.target.value); }, value: languageConfig.filter(lang => lang.label === activeLangLabel)[0].url }, languageConfig.map(lang => React.createElement("option", { key: lang.keyIdentifier, value: lang.url }, lang.label)))))));
};
LanguageSwitch.defaultProps = {
    isLanguageSwitch: true,
};
export default LanguageSwitch;
