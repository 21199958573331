import React from 'react';
import ArrowsCta from '../arrows-cta';
import Icon from '../icon';
const Link = ({ children, href, classes = [], attrs = {}, icon, iconsCTA, isActiveLink = false, }) => {
    const cls = ['a-link', isActiveLink ? 'a-link__activeLink' : '', ...classes];
    function iconSwitch(param) {
        switch (param) {
            case 'arrow':
                return React.createElement(ArrowsCta, { icon: "arrow-right" }, children);
            case 'download':
                return React.createElement(ArrowsCta, { icon: "download" }, children);
            default:
                return children;
        }
    }
    if (Array.isArray(children)) {
        const label = icon;
        console.log(children);
        return (React.createElement(React.Fragment, null,
            React.createElement("a", { href: href, className: cls.join(' ') }, label),
            React.createElement("div", { className: "m-header-main-nav__subMenu" },
                React.createElement("ul", null, children.map((child) => {
                    return (React.createElement("li", { key: encodeURIComponent(child.children.toString()), "data-active": child.isActiveLink },
                        React.createElement("a", { href: child.href, className: "m-header-main-nav__subMenuLink" }, child.children)));
                })))));
    }
    return (React.createElement("a", Object.assign({ href: href, className: cls.join(' ') }, attrs),
        icon ? React.createElement(Icon, { name: icon }) : '',
        iconsCTA ? iconSwitch(iconsCTA) : children));
};
Link.defaultProps = {
    classes: [],
    attrs: {},
    isActiveLink: false,
};
export default Link;
