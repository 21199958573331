export const INITIAL_STATE = {
    isCollapsed: false,
    isClicked: false,
    isSidebarOpen: false,
};
export const TOGGLE_SIDEBAR = 'toggle_sidebar';
export const COLLAPSE_HEADER = 'collapse_header';
export const UNCOLLAPSE_HEADER = 'uncollapse_header';
export const TOGGLE_COLLAPSE_HEADER = 'toggle_collapse_header';
const reducer = (state, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return {
                isCollapsed: false,
                isClicked: false,
                isSidebarOpen: !state.isSidebarOpen,
            };
        case COLLAPSE_HEADER:
            return {
                isCollapsed: true,
                isClicked: false,
                isSidebarOpen: false,
            };
        case UNCOLLAPSE_HEADER:
            return {
                isCollapsed: false,
                isClicked: false,
                isSidebarOpen: false,
            };
        case TOGGLE_COLLAPSE_HEADER:
            return {
                isCollapsed: !state.isCollapsed,
                isClicked: state.isCollapsed,
                isSidebarOpen: false,
            };
        default:
            throw new Error('Invalid HeaderAction');
    }
};
export default reducer;
