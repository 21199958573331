import React from 'react';
import HeaderBrand from '../../02-molecules/header-brand';
import HeaderActions from '../../02-molecules/header-actions';
import HeaderMetaNav from '../../02-molecules/header-meta-nav';
import Usermenu from '../../02-molecules/usermenu';
import HeaderCollapseTrigger from '../../02-molecules/header-collapse-trigger';
import HeaderSidebarTrigger from '../../02-molecules/header-sidebar-trigger';
import LanguageSwitch from '../../02-molecules/language-switch';
import UsermenuAvatar from '../../02-molecules/usermenu-avatar';
import HeaderSearchForm from '../../02-molecules/header-search-form';
import MetaLink from '../../01-atoms/meta-link';
const HeaderTop = ({ headerTriggerLabel, username, isClicked, activeLangLabel, isLoggedIn, collapseHandlerReact, sidebarHandlerReact, isSidebarOpen, languageConfig, metaNavLinks, loginUrl, loginLabel, logoutUrl, logoutLabel, usermenuActions, directSearchUrl, searchFormAction, searchlabel, searchPlaceholder, additionalActions, }) => {
    let amountOfHeaderActionsElements = (searchFormAction !== null) ? 1 : 0;
    if (additionalActions !== undefined) {
        amountOfHeaderActionsElements += additionalActions.length;
    }
    const baseClass = 'o-header-top';
    return (React.createElement("div", { className: baseClass },
        React.createElement("div", { className: `${baseClass}__left` },
            React.createElement("div", { className: `${baseClass}__collapse-trigger` },
                React.createElement(HeaderCollapseTrigger, { handler: collapseHandlerReact, isClicked: isClicked, titel: headerTriggerLabel })),
            React.createElement("div", { className: `${baseClass}__sidebar-trigger` },
                React.createElement(HeaderSidebarTrigger, { isOpen: isSidebarOpen, handler: sidebarHandlerReact, titel: headerTriggerLabel })),
            React.createElement("div", { className: `${baseClass}__metaNav` },
                React.createElement(HeaderMetaNav, { metaNavLinks: metaNavLinks !== null && metaNavLinks !== void 0 ? metaNavLinks : [] }))),
        React.createElement("div", { className: `${baseClass}__right` },
            React.createElement("div", { className: `${baseClass}__actions` },
                React.createElement(HeaderActions, null,
                    searchFormAction !== null || directSearchUrl !== null
                        ? (React.createElement(HeaderSearchForm, { searchLabel: searchlabel, directSearchUrl: directSearchUrl, searchFormAction: searchFormAction, placeholder: searchPlaceholder }))
                        : (''),
                    additionalActions !== undefined && additionalActions.length > 0
                        ? (additionalActions.map((link, index) => (React.createElement("div", { className: "m-header-actions__additionalItem", key: `headerActionsIndex_${index.toString()}` },
                            React.createElement(MetaLink, { href: link.href, icon: link.icon, label: link.label, key: `headerActionsIndex_${index.toString()}` })))))
                        : (''),
                    usermenuActions !== undefined && usermenuActions.length > 0
                        ? (React.createElement(UsermenuAvatar, { isLoggedIn: isLoggedIn, loginUrl: loginUrl, loginLabel: loginLabel, username: username, usermenuActions: usermenuActions, amountOfHeaderActionsElements: amountOfHeaderActionsElements }))
                        : (React.createElement(Usermenu, { isLoggedIn: isLoggedIn, username: username, loginUrl: loginUrl, loginLabel: loginLabel, logoutUrl: logoutUrl, logoutLabel: logoutLabel })),
                    languageConfig !== null && languageConfig !== undefined
                        ? (React.createElement(LanguageSwitch, { activeLangLabel: activeLangLabel, languageConfig: languageConfig, dropdownKeyAddition: "headerTop" }))
                        : (''))),
            React.createElement("div", { className: `${baseClass}__brand-container` },
                React.createElement(HeaderBrand, null)))));
};
HeaderTop.defaultProps = {
    metaNavLinks: [],
    username: null,
    usermenuActions: [],
    directSearchUrl: null,
    searchFormAction: null,
    additionalActions: [],
    languageConfig: null,
};
export default HeaderTop;
