import React from 'react';
import Logo from './logos/Logo';
const logos = {
    standard: Logo,
};
const Icon = ({ name }) => {
    const Log = logos[name];
    return React.createElement(Log, null);
};
export default Icon;
