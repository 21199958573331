const Slider = () => {
    const checkitem = () => {
        const $helpPopupCarousel = $('#help-popup-carousel');
        if ($('.carousel-inner .item:first').hasClass('active')) {
            $helpPopupCarousel.children('.left.carousel-control').hide();
            $helpPopupCarousel.children('.right.carousel-control').show();
        }
        else if ($('.carousel-inner .item:last').hasClass('active')) {
            $helpPopupCarousel.children('.left.carousel-control').show();
            $helpPopupCarousel.children('.right.carousel-control').hide();
        }
        else {
            $helpPopupCarousel.children('.carousel-control').show();
        }
    };
    $('#help-popup-carousel').on('slid.bs.carousel', '', checkitem);
    checkitem();
};
export default Slider;
