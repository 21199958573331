import React from 'react';
import Icon from '../../01-atoms/icon';
const HeaderActionsDropdownToggle = ({ icon, label = null, maxLabelLength = 30, onClick, open, }) => {
    const trimLabel = () => {
        if (label === null) {
            return '';
        }
        if (label.length <= maxLabelLength) {
            return label;
        }
        return `${label.substring(0, (maxLabelLength - 3))}...`;
    };
    const baseClassName = 'm-headerActionsDropdownToggle';
    return (React.createElement("button", { type: "button", className: `${baseClassName} ${open ? '' : '-closed'}`, "aria-expanded": open, onClick: onClick },
        icon ? React.createElement(Icon, { name: icon }) : '',
        React.createElement("div", { className: `${baseClassName}__label` },
            React.createElement("span", null, trimLabel())),
        React.createElement("div", { className: `${baseClassName}__toggleIndicator`, "aria-expanded": open },
            React.createElement(Icon, { name: "chevron-down" }))));
};
HeaderActionsDropdownToggle.defaultProps = {
    icon: null,
    label: null,
    maxLabelLength: 30,
};
export default HeaderActionsDropdownToggle;
