import React from 'react';
import Tippy from '@tippyjs/react';
import Icon from '../../01-atoms/icon';
import Button from '../../01-atoms/button';
const Tooltip = props => {
    const { classes = [], children, handleCloseClick, message, showCloseButton, tippyProps, wrappedContentProps, } = props;
    const cls = ['m-tooltip', ...classes];
    if (showCloseButton) {
        cls.push('m-tooltip--with-icon');
    }
    if (typeof tippyProps !== 'undefined' && (tippyProps.placement === 'left' || tippyProps.placement === 'right')) {
        console.error('Not implemented');
    }
    return (React.createElement(Tippy, Object.assign({ content: (React.createElement("div", { className: cls.join(' '), role: "tooltip", dir: "ltr" },
            React.createElement("div", { className: "m-tooltip-content" }, message),
            showCloseButton
                && (React.createElement(Button, { classes: ['a-btn--icon a-btn--close'], handleClick: handleCloseClick },
                    React.createElement(Icon, { name: "circle-cross" }))))) }, tippyProps),
        React.createElement("span", Object.assign({}, wrappedContentProps), children)));
};
export default Tooltip;
