import React, { useReducer } from 'react';
import Icon from '../../01-atoms/icon';
import Tooltip from '../tooltip';
import reducer from '../tooltip/stories/reducer';
import { DEFAULT_STATE } from '../tooltip/stories/models';
const Usermenu = ({ isLoggedIn, username, loginUrl, loginLabel, logoutUrl, logoutLabel, }) => {
    const tooltipContent = username !== null && username !== void 0 ? username : '';
    const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
    const tippyProps = {
        visible: state.visible,
        theme: 'userlogin',
    };
    const wrappedContentProps = {
        onMouseEnter: () => {
            showTooltip();
        },
        onMouseLeave: () => {
            hideTooltip();
        },
    };
    const showTooltip = () => {
        dispatch({
            type: 'SHOW_TOOLTIP',
        });
    };
    const hideTooltip = () => {
        dispatch({
            type: 'HIDE_TOOLTIP',
        });
    };
    if (isLoggedIn) {
        return (React.createElement("a", { className: "m-usermenu", href: logoutUrl },
            React.createElement(Tooltip, { message: tooltipContent, showCloseButton: false, tippyProps: tippyProps, wrappedContentProps: wrappedContentProps },
                React.createElement(Icon, { name: "user" }),
                React.createElement("span", { className: "m-usermenu__label" }, logoutLabel))));
    }
    return (React.createElement("a", { className: "m-usermenu", href: loginUrl },
        React.createElement("span", null,
            React.createElement(Icon, { name: "user" }),
            React.createElement("span", { className: "m-usermenu__label" }, loginLabel))));
};
Usermenu.defaultProps = {
    username: null,
};
export default Usermenu;
