import React from 'react';
import ArrowRight from './icons/ArrowRight';
import ArrowUpCircle from './icons/ArrowUpCircle';
import Checkmark from './icons/Checkmark';
import ChevronDown from './icons/ChevronDown';
import ChevronUp from './icons/ChevronUp';
import Cross from './icons/Cross';
import Download from './icons/Download';
import Exit from './icons/Exit';
import Globe from './icons/Globe';
import Lock from './icons/Lock';
import MagnifyingGlass from './icons/MagnifyingGlass';
import SignExclamationPoint from './icons/SignExclamationPoint';
import Facebook from './icons/Facebook';
import Twitter from './icons/Twitter';
import Youtube from './icons/Youtube';
import Instagram from './icons/Instagram';
import Plus from './icons/Plus';
import Minus from './icons/Minus';
import Trash from './icons/Trash';
import User from './icons/User';
import CircleCross from './icons/CircleCross';
import HamburgerMenu from './icons/HamburgerMenu';
import Home from './icons/Home';
import ToggleOff from './icons/ToggleOff';
import ToggleOn from './icons/ToggleOn';
import Help from './icons/Help';
const icons = {
    'arrow-right': ArrowRight,
    'arrow-up-circle': ArrowUpCircle,
    'chevron-down': ChevronDown,
    'chevron-up': ChevronUp,
    'magnifying-glass': MagnifyingGlass,
    'sign-exclamation-point': SignExclamationPoint,
    checkmark: Checkmark,
    'circle-cross': CircleCross,
    cross: Cross,
    download: Download,
    exit: Exit,
    facebook: Facebook,
    globe: Globe,
    home: Home,
    help: Help,
    lock: Lock,
    'hamburger-menu': HamburgerMenu,
    instagram: Instagram,
    minus: Minus,
    plus: Plus,
    trash: Trash,
    toggleOff: ToggleOff,
    toggleOn: ToggleOn,
    twitter: Twitter,
    user: User,
    youtube: Youtube,
};
const Icon = ({ name }) => {
    const Ico = icons[name];
    return React.createElement(Ico, null);
};
export default Icon;
