import Cookies from '../helpers/cookies';
const USER_SURVEY_COOKIE_KEY_BASE = 'survey';
const COOKIE_VALUE_DONT_SHOW_AGAIN = 'd';
const SURVEY_ACTION_SEEN = 'seen';
const SURVEY_ACTION_OTHER_TIME = 'otherTime';
const SURVEY_ACTION_NO_INTEREST = 'noInterest';
const UserSurvey = () => {
    const userSurveys = document.querySelectorAll('.js-user-survey');
    if (userSurveys.length === 0) {
        return;
    }
    const handleSurveyVisibility = (survey) => {
        const cookieKey = `${USER_SURVEY_COOKIE_KEY_BASE}_${survey.dataset.elvId}`;
        if (Cookies.get(cookieKey) === COOKIE_VALUE_DONT_SHOW_AGAIN) {
            return;
        }
        const showAfterMs = parseInt(survey.dataset.showAfter, 10);
        setTimeout(() => {
            survey.classList.add('show');
        }, showAfterMs * 1000);
    };
    const setSurveyValCookie = (elvId, val) => {
        const cookieKey = `${USER_SURVEY_COOKIE_KEY_BASE}_${elvId}`;
        Cookies.set(cookieKey, val);
    };
    const handleSurveyActions = (survey) => {
        const buttonList = survey.querySelectorAll('button');
        const anchor = survey.querySelector('.js-user-survey-link');
        const surveyElvId = survey.dataset.elvId;
        anchor.addEventListener('click', () => {
            setSurveyValCookie(survey.dataset.elvId, COOKIE_VALUE_DONT_SHOW_AGAIN);
        });
        for (const button of Array.from(buttonList)) {
            button.addEventListener('click', () => {
                const action = button.dataset.value;
                if (action === SURVEY_ACTION_SEEN) {
                    anchor.click();
                }
                else if (action === SURVEY_ACTION_OTHER_TIME) {
                    setSurveyValCookie(surveyElvId, '0');
                }
                else if (action === SURVEY_ACTION_NO_INTEREST) {
                    setSurveyValCookie(surveyElvId, COOKIE_VALUE_DONT_SHOW_AGAIN);
                }
                survey.classList.remove('show');
            });
        }
    };
    for (const survey of Array.from(userSurveys)) {
        handleSurveyVisibility(survey);
        handleSurveyActions(survey);
    }
};
export default UserSurvey;
