import React from 'react';
const HeaderActions = ({ children, classes = [] }) => (
// eslint-disable-next-line no-template-curly-in-string
React.createElement("div", { className: "m-header-actions" },
    React.createElement("ul", { className: ` ${classes.join(' ')}` }, children && children.map((metalink, index) => {
        let isLanguageSwitch = false;
        // @ts-ignore
        if (typeof metalink.props !== 'undefined') {
            // @ts-ignore
            isLanguageSwitch = typeof metalink.props.isLanguageSwitch === 'boolean';
        }
        return (React.createElement("li", { key: `metalink-${index.toString()}`, className: `m-header-actions__item ${isLanguageSwitch ? '-isLanguageSwitch' : ''}` }, metalink));
    }))));
export default HeaderActions;
