import React, { useState } from 'react';
import AccordionBtn from '../accordion-btn';
const AccordionPanel = ({ children, id, labelledby, active, classes = [], isList = false, elem, }) => {
    const cls = active ? 'a-accordion-panel a-accordion-panel--active' : 'a-accordion-panel';
    const [isActive, handleIsActive] = useState(false);
    function toggleAccordion() {
        handleIsActive(!isActive);
    }
    return (React.createElement(React.Fragment, null, isList && elem !== undefined ? (React.createElement("li", { className: isActive ? 'mm-active' : '' }, elem.children ? (React.createElement(React.Fragment, null,
        React.createElement(AccordionBtn, { id: "1", active: isActive, handleClick: toggleAccordion }, elem.title),
        React.createElement("ul", null, elem.children && elem.children.map(item => (React.createElement(AccordionPanel, { isList: true, elem: item }, item.children))))))
        : (React.createElement("span", { className: "a-accordion-btn" }, elem.title)))) : (React.createElement("div", { "aria-hidden": !active, id: `accordion-panel-${id}`, "aria-labelledby": labelledby, className: `${cls} ${classes.join(' ')}` }, children))));
};
export default AccordionPanel;
